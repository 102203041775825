<template>
    <section>
      <div class="normalInputRow">
        <div class="input-container">
          <p class="input-label">房型<span style="color: #d60012;">*</span></p>
          <div class="fitBox">
            <a :class="formData.itemName == item.val && 'selectFit'" @click="choseType(item, index, 'itemName')" v-for="(item, index) in btnList" :key="index">{{ item.txt }}</a>
          </div>
        </div>
      </div>
        <div class="normalInputRow" v-for="(item, index) in formList" :key="index">
          <iInput
              v-if="item.isShow"
              noCancel="false"
              :title="item.title"
              v-model="formData[item.vModel]"
              :placeholder="item.pla"
              :required="item.must"
              :type="item.type"
              :disabled="item.disabled"
              @click.native.stop='methodType(item)'
          ></iInput>
        </div>
    </section>
</template>
<script>
    import {common} from "./common.js";
    import vueFilter from "@/common/filter/vuefilter.js";
    import dateController from "@/components/datePicker/dateController";
    import { Field } from 'vant';
    export default {
        name: 'addAccommodation',
        mixins: [common, dateController],
        components: {
            [Field.name]: Field,
        },
        data () {
            return {
                /**
                 * title 标签
                 * vModel 绑定值
                 * isShow 该选项是否根据其他条件判断显示---或者---单独判断显示不同样式选项
                 * must 是否必填
                 * disabled 是否只读
                 * method 点击执行函数
                 * parameter 函数执行参数
                 */
                formList: [
                    {title:'间数',pla: '请输入间数',isShow: true,disabled: false,must: true,type: 'tel',vModel: 'qty1', method: '',parameter: ''},
                    {title:'入住时间',pla: '请选择入住时间',isShow: true,disabled: true,must: true,type: 'text',vModel: 'dtStart', method: 'selectTime',parameter: 'dtStart'},
                    {title:'离店时间',pla: '请选择离店时间',isShow: true,disabled: true,must: true,type: 'text',vModel: 'dtEnd', method: 'selectTime',parameter: 'dtEnd'},
                    {title:'备注',pla: '请填写备注信息',isShow: true,disabled: false,must: false,type: 'textarea',vModel: 'descr', method: '',parameter: ''},
                ],
                formData: {},
                btnList: []
            }
        },
        created () {
            this.$eventHub.$on('goVenue', () => {
                this.setVenueItem()
            })
            this.init()
        },
        methods: {
            async init() {
                this.btnList = await this.getTableTypeList("RoomType")
                let formData = this.$SmartStorage.get('venueItem')
                if (formData) {
                    this.$set(this.formData, 'qty1', formData.qty1)
                    this.$set(this.formData, 'itemId', formData.itemId)
                  this.$set(this.formData, 'dtStart', vueFilter.formatDate(this.isDate(formData.dtStart), 'yyyy-MM-dd'))
                  this.$set(this.formData, 'dtEnd', vueFilter.formatDate(this.isDate(formData.dtEnd), 'yyyy-MM-dd'))
                    this.$set(this.formData, 'descr', formData.descr)
                    this.$set(this.formData, 'itemName', formData.itemName)
                }
            },
            methodType (item) {
                if(item.method ==''){ return }
                this[item.method](item.parameter)
            },
            // 住宿时间，范围应该是会议开始前一天到会议结束后一天
            selectTime (params) {
                let startTime =  vueFilter.formatDate(this.startTime,'yyyy/MM/dd')
                let endTime =  vueFilter.formatDate(this.endTime,'yyyy/MM/dd')
                let tempEndTime = +new Date(endTime) + 24*60*60*1000;
                let tempStartTime = +new Date(startTime) - 24*60*60*1000;
                startTime = new Date(tempStartTime)
                endTime = new Date(tempEndTime);
                startTime = vueFilter.formatDate(startTime,'yyyy/MM/dd');
                endTime = vueFilter.formatDate(endTime,'yyyy/MM/dd');
                this.openSelectTime(startTime,endTime,this.formData[params] || vueFilter.formatDate(new Date()),params, 'yyyy/MM/dd', 'date');
            },
            choseType (item, index, name) {
                this.$set(this.formData, name, item.val)
            },
            // 待提交数据处理
            formProcessing () {
                let toast = this.veryFilter();
                if (toast) {
                    this.$toast(toast);
                    return false;
                }
                let params = {
                    dtStart: this.formData.dtStart,//需求开始日期
                    dtEnd: this.formData.dtEnd,//需求结束日期
                    descr: this.formData.descr,//需求备注
                    qty1: Number(this.formData.qty1),//房间数量
                    itemName: this.formData.itemName,
                    unit1: '间',
                    unit2: '晚',
                    qty2: Math.ceil((new Date(this.formData.dtEnd).getTime() - new Date(this.formData.dtStart).getTime()) / 86400000),
                    itsItemData: {
                        refer:false,
                    }
                }
                if (this.formData.itemId) {
                    params.itemId = this.formData.itemId
                }
                return params
            },
            veryFilter() {
                let toast = '';
                if (!this.formData.itemName) {
                    toast = '请选择房间类型！';
                    return toast;
                }else if (!this.formData.qty1) {
                    toast = '请输入房间数量！';
                    return toast;
                }else if (!this.formData.dtStart) {
                    toast = '请选择入住时间！';
                    return toast;
                }else if (!this.formData.dtEnd) {
                    toast = '请选择退房时间！';
                    return toast;
                }
                let dtStart = `${this.formData.dtStart}:00`;
                let dtEnd = `${this.formData.dtEnd}:00`;

                if (dtStart >= dtEnd) {
                    toast = '入住时间不能大于等于退房时间!';
                    return toast;
                }

                return null;
            },
            setVenueItem () {
                this.$SmartStorage.set('venueItem', this.formData)
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import "addDemand";
</style>
