<template>
  <div class="selectSupplier" :class="isCanAdd? 'edit' : ''">
    <p class="selectSupplierTitle">选择酒店<span class="tips">最多可选择{{ maxSelect }}家供应商</span></p>
    <div class="selectSupplierList">
      <div class="selectSupplierItem" v-for="(item, index) in venueList" :key="index">
        <img :src="item.coverUrl || coverUrl"/>
        <div class="selectSupplierInfo">
          <p class="title">{{item.name}}</p>
          <p class="info">{{item.address}}</p>
        </div>
        <div class="deleteIcon">
          <svg class="icon" aria-hidden="true" @click="deleteSuppler(item)" v-if="isCanAdd">
            <use xlink:href="#iconclosewhite"></use>
          </svg>
        </div>
      </div>
    </div>
    <div class="addTeaBtn" @click="addSupplier" v-if="isCanAdd">
      <svg class="icon" aria-hidden="true">
        <use xlink:href="#icon-hao"></use>
      </svg>
    </div>
  </div>
</template>
<script>
import {Cell, CellGroup} from 'vant'
export default {
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
  },
  props: {
    isCanAdd: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      maxSelect: 3,
      venueList: [],
      coverUrl: require('../../../assets/img/detailsImg.png')
    }
  },
  created () {
    this.venueList = this.$SmartStorage.get('venueList') || []
  },
  methods: {
    addSupplier () {
      this.$eventHub.$emit('goVenue')
      this.$SmartStorage.set('maxSelect', this.maxSelect)
      this.$router.push('/venueList')
    },
    deleteSuppler (item) {
      this.venueList.splice(this.venueList.findIndex(x => x.supplierId == item.supplierId), 1)
      this.$SmartStorage.set('venueList', this.venueList)
    },
    formProcessing () {
      if (this.venueList && !this.venueList.length) {
        this.$toast('请至少选择一个供应商!');
        return
      }
      return this.venueList
    }
  }
}
</script>
<style scoped lang="scss">
.selectSupplier {
  padding: .1rem;
  background-color: #F5F5F5;
  .selectSupplierTitle {
    font-size: .17rem;
    font-weight: bold;
    color: #13161B;
    line-height: 20px;
    text-align: left;
    margin: 0.1rem 0;
    .tips{
      font-size: .1rem;
      font-weight: 500;
      color: #E60012;
      line-height: 20px;
    }
  }
  .addTeaBtn {
    padding: .15rem;
    margin: 10px 0;
    border: 1px dashed #ccc;
    border-radius: .1rem;
    .icon {
      width:.3rem;
      height:.3rem;
    }
  }
  .selectSupplierItem {
    background: #FFFFFF;
    box-shadow: 0px 5px 19px 0px rgba(8,37,105,0.1);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: .1rem;
    margin: .08rem 0;
    img{
      width: .5rem;
      height: .5rem;
      border-radius: .05rem;
    }
    .selectSupplierInfo {
      text-align: left;
      flex: 1 1 2%;
      margin-left: 0.1rem;
      width: 60%;
      .title{
        font-size: .16rem;
        font-weight: 500;
        color: #13161B;
        line-height: 17px;
      }
      .info{
        font-size: .12rem;
        color: #ccc;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
.edit{
  margin-bottom: 1rem;
}
</style>
