import {getCookie} from 'tiny-cookie'
import vueFilter from "@/common/filter/vuefilter";
export const common = {
    data () {
        return {
            tenant: this.$cookie.get('tenant'),
            startTime: this.$SmartStorage.get('eventData').dtStart || JSON.parse(getCookie('eventData')).dtStart || '', // 会议开始时间
            endTime: this.$SmartStorage.get('eventData').dtEnd ||JSON.parse(getCookie('eventData')).dtEnd || '', // 会议结束时间
            isTimeFrame: false,
        }
    },
    computed: {
    },
    created () {
    },
    methods: {
        async getTableTypeList(code) {
            let data = this.$SmartStorage.get(code) || []
            if (data.length) {
                return data
            }
            let params = {
                keyCode: code
            }
            let response = await this.$service.query(params)
            if (response.success) {
                this.$SmartStorage.set(code, response.content)
                return response.content
            }
        },
        setSelectTime (target, time, formatDate) {
            if (['dtStart'].includes(target)) {
                if(this.formData.dtEnd && new Date(time) > new Date(this.formData.dtEnd)){
                    this.$toast('开始时间需要小于结束时间！');
                }else{
                    this.$set(this.formData, 'dtStart',vueFilter.formatDate(time,formatDate))
                    // pfizer的用餐类型，根据开始时间来确定(14:00之前都为午餐，14:00之后都为晚餐)
                    if(['pfizer','viatris','ipsen', 'crossevent'].includes(this.tenant) && this.isMeal){
                        let temp = new Date(time).getHours();
                        if(temp >= 14){
                            this.$set(this.formData, 'itemName','晚餐')
                        }else{
                            this.$set(this.formData, 'itemName','午餐')
                        }
                    }
                }
            }
            if (['dtEnd'].includes(target)) {
                if(this.formData.dtStart && new Date(time) < new Date(this.formData.dtStart)){
                    this.$toast('结束时间需要大于开始时间！')
                }else{
                    this.$set(this.formData, 'dtEnd',vueFilter.formatDate(time,formatDate))
                }
            }
        },
        isDate (date) {
            if (date) {
                return date.replace('T', ' ')
            }
            return null
        },
        // 获取时间范围是否开启
        async getTimeFrame (type) {
            let eventData = JSON.parse(getCookie('eventData'))
            let typeDictVal = eventData.typeDictTxt || eventData.typeDictVal
            let params = {
                collection: "cfg-ivenue-operation",
                filter: {
                    tenantCode: this.tenant_code,
                    typeDictVal: { $in: [typeDictVal, '*'] },
                    tenantId: getCookie("tenant_id") || "",
                    type: type
                }
            }
            let res = await this.$service.getsettings(params)
            if (res && res.success) {
                this.isTimeFrame = res.content ? !!res.content.isTimeFrame : false
            }
        },
    }
}
